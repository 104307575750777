<template>
  <div class="menu" v-if="chef">
    <v-dialog v-model="gallery" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-btn icon @click="gallery = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="gallery">
          <div
              v-for="(src, index) in  chef.photos"
              :key="index"
              class="pic"
              @click="photoDetails=true;photoIndex=index;$log(photoIndex)"
          >
            <!--                                @click="() => showImg(index)">-->
            <img :src="src.photoPath+src.photoFilename" class="gallery-pic">
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="photoDetails" fullscreen content-class="caroussel-dialog-fullscreen" hide-overlay>
      <v-btn icon dark @click="photoDetails = false" style="position: absolute;z-index: 1;">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-carousel v-if="chef"
                  show-arrows
                  hide-delimiters
                  class="caroussel-fullscreen"
                  v-model="photoIndex"
                  height="100%"
      >
        <v-carousel-item contain
                         v-for="(photo,index) in chef.photos"
                         :key="index"
                         :src="photo.photoPath+photo.photoFilename"

        ></v-carousel-item>
      </v-carousel>
    </v-dialog>
    <v-carousel v-if="chef"
                show-arrows
                hide-delimiters
                class="caroussel"
                height="250px"
    >
      <v-carousel-item
          v-for="(photo,index) in chef.photos"
          :key="index"
          :src="photo.photoPath+photo.photoFilename"
          @click="gallery = true"
      ></v-carousel-item>
      <v-row class="chefSearchBottom " align="end" justify="end" no-gutters style="padding: 0px">
        <v-container>
          <v-btn outlined @click="gallery = true" class="btn-black-30">
            Gallery
          </v-btn>
        </v-container>
      </v-row>
    </v-carousel>
      <v-container fuild style="margin-top: -48px;">
        <v-card v-if="chef">
          <v-row style="" class="margin-bottom margin-left-right-default">
            <v-col align="start" class="col-auto">
              <v-avatar v-if="chef.profile.photoPath && chef.profile.photoFilename" size="124">
                <img style="object-fit: cover;"
                     :src="chef.profile.photoPath+chef.profile.photoFilename"
                     :alt="chef.profile.profileName"
                />
              </v-avatar>
            </v-col>
            <v-col align-self="center" justify="start">
              <div class="flex-row" style="white-space: nowrap;font-size: x-large;">
                {{ chef.profile.profileName }}
              </div>
              <div class="flex-row" style="white-space: nowrap;font-size: large;"> {{ chef.location
                }}
              </div>
            </v-col>
            <v-col align="end" align-self="center" class="col-auto">
              <star-rating read-only :increment="0.5" v-model="rating" :star-size="35" align="end"
                           align-self="center" inline></star-rating>
            </v-col>
          </v-row>
<!--          <v-row style="margin-left: 12px;margin-right: 12px" align-content="center" align="center"-->
<!--                 justify="center">-->
<!--            <a href="javascript:void(0)"-->
<!--               @click="toggleChef()">-->
<!--              {{aboutChef?"less":"more"}} about the chef-->
<!--            </a>-->
<!--          </v-row>-->

          <v-divider class="margin-left-right-default margin-top-bottom"></v-divider>
          <about-chef v-if="chef && aboutChef" :chef="chef" ></about-chef>
        </v-card>

      </v-container>



<!--      <v-container v-if="chef && aboutChef">-->

<!--      </v-container>-->

    <v-container>
      <services :chefselected="chef" ></services>
    </v-container>

    <v-container>
      <v-toolbar
          flat
      >
        <div v-if="events.length===0">No Availability</div>
        <div v-else>Availability</div>
        <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.prev()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendar" class="no-margin-padding" style="display:inline;padding-left: 0px">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.next()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <div class="margin-left-right-default" style="padding: 2px 5px 2px 5px">Legend: </div>
        <div class="confirmed margin-left-right-default" style="padding: 2px 5px 2px 5px">Available</div>
      </v-toolbar>
      <v-calendar
          ref="calendar"
          v-model="value"
          class="test"
          :weekdays="weekday"
          :events="events"
          :event-color="getEventColor"
          :event-text-color="getEventTextColor"
          type="month"
          event-overlap-mode="column"
          :event-overlap-threshold="30"
          color="primary"
      >
      </v-calendar>

    </v-container>

    <v-container v-if="chef">
      <v-dialog v-model="gallery" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
          <v-btn icon @click="gallery = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div class="gallery">
            <div
                v-for="(photo, index) in  chef.photos"
                :key="index"
                class="pic"
                @click="photoDetails=true;photoIndex=index;$log(photoIndex)"
            >
              <!--                                @click="() => showImg(index)">-->
              <img :src="photo.photoPath+'medium.'+photo.photoFilename" class="gallery-pic">
            </div>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="photoDetails" fullscreen content-class="caroussel-dialog-fullscreen" hide-overlay>
        <!--            <template v-slot:activator="{ on }">-->
        <!--                <v-btn color="primary" light v-on="on">Open Dialog</v-btn>-->
        <!--            </template>-->
        <v-btn icon dark @click="photoDetails = false" style="position: absolute;z-index: 1;">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-carousel
                    show-arrows
                    hide-delimiters
                    class="caroussel-fullscreen"
                    v-model="photoIndex"
                    height="100%"
        >
          <v-carousel-item contain
                           v-for="(photo,index) in chef.photos"
                           :key="index"
                           :src="photo.photoPath+'high.'+photo.photoFilename"
          ></v-carousel-item>
        </v-carousel>
      </v-dialog>
      <v-carousel
          show-arrows
          hide-delimiters
          class="caroussel"
          height="250px"
          style="margin-top: 12px"
      >
        <v-carousel-item
            v-for="(item, i) in chef.photos"
            :key="i"
            :src="item.photoPath+'medium.'+item.photoFilename"
        ></v-carousel-item>
        <v-row class="rowGalleryMenuOverlay " align="end" justify="end" no-gutters style="padding: 0px">
          <v-container>
            <v-btn outlined @click="gallery = true" class="btn-black-30">
              Gallery
            </v-btn>
          </v-container>
        </v-row>
      </v-carousel>
    </v-container>

  </div>
  <div v-else>
    <div style="height: 0px">

      <v-progress-linear v-if="isLoading"
                         indeterminate
                         height="6"
      ></v-progress-linear>
      <h1 v-if="isLoading" style="text-align: center;">We are loading the profile please wait ...</h1>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>
import StarRating from 'vue-star-rating'
import AboutChef from '../components/AboutChef';
import Services from '../components/Services';
import Vue from "vue";
Vue.component('star-rating', StarRating);
Vue.component('about-chef', AboutChef);
Vue.component('services', Services);

export default {
  name: "Profile",
  data() {
    return {
      chef: undefined,
      rating:5,
      valid:true,
      isLoading:false,
      aboutChef: true,
      gallery: false,
      photoDetails: false,
      photoIndex: 0,
      value:undefined,
      weekday: [1, 2, 3, 4, 5, 6, 0],
      events:[],
    };
  },

  beforeCreate() {

  },
  mounted() {

    let name=this.$route.params.name.replaceAll(' ', '-').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    if (name!==undefined){
      this.isLoading=true
      console.log(name);
      this.$store.dispatch('profile',{name:name}).then((data)=>{
        console.log(data);
        this.chef=data.result;

        if (this.chef !==undefined){
          this.events = this.chef.events.filter(e => e.status.name ==="AVAILABLE" ).sort((a, b) => a.eventTime.order - b.eventTime.order);
          this.events.forEach(e=>{

            e.start=new Date(e.date);
            e.statusValue=e.status.name;
            e.name=e.eventTime.displayName;
            if (this.value==undefined){
              this.value=e.start;
            }
          })
          if (this.value===undefined){
            this.value=new Date();
            // this.$refs.calendar.move(0);
          }
        }else{
          this.$router.push("/");
        }

      })
          .finally(() => {
            this.$forceUpdate();
            this.isLoading = false
          });
    }else{
      this.$router.push("/");
    }
  },
  methods: {
    toggleChef() {
      this.aboutChef = !this.aboutChef;
    },
    getEventColor (event) {
      if (event.status.name==="AVAILABLE"){
        return "confirmed";
      }else if (event.status.name==="UNAVAILABLE"){
        return "unavailable";
      }

      // return event.class
    },
    getEventTextColor (event) {
      if (event.status.name==="AVAILABLE"){
        return "black";
      }else if (event.status.name==="UNAVAILABLE"){
        return "white";
      }else{
        return "white";
      }

      // return event.class
    },
    getClass (event) {
      console.log("getClass" + event);
      return "black--text"
    },
  }
}
</script>