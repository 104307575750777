<template>
<!--  <v-row style="margin-left: 0px;margin-right: 0px">-->
<div>
      <div v-if="chefselected" >
        <div v-for="(service,index) in serviceOrdered" :key="index">
          <v-divider v-if="index!=0" style="margin-top: 12px;margin-bottom: 12px;" dark></v-divider>
          <v-card >
            <v-col align-self="center">
              <v-row style="text-align: center;font-size: medium;font-weight: 700;margin: -12px -12px 0px -12px;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">
                <v-col cols="1" align="start"></v-col>
                <v-col cols="10"><div style="text-align: center;font-size: medium;font-weight: 700;margin: -12px -12px 0px -12px;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">{{service.serviceType.displayName}}</div></v-col>
                <v-col cols="1" align="end">
                  $ per person
                </v-col>
              </v-row>
              <div v-for="(category,indexCat) in service.itemCategories" :key="category.name" class="margin-top-default">
                <div v-if="filteredDishesByCategory(service,category).length>0 ">
                  <v-divider v-if="indexCat!=0" style="margin-top: 12px;margin-bottom: 12px;"></v-divider>
                  <div class="" style="text-align: center;">
                    <v-row>
                      <v-col align="start">
                        <div style="display:inline;text-align: left;font-weight:bold ;"
                             class="primary--text">
                          <!--                                                      {{dishType.displayName}}-->
                          {{category.name}}
                        </div>
                      </v-col>

                      <v-col class="" cols="2" style="align-self: center">
                      </v-col>
                    </v-row>
                  </div>
                  <v-row style="text-align: left;" v-for="(dish,indexDish) in filteredDishesByCategory(service,category)"
                         v-bind:key="indexDish">
                    <v-col style="align-self: center;margin-left: 12px;">
                      <v-row no-gutters style="align-self: center">
                        <v-col>
                          {{dish.description}}
                        </v-col>
                      </v-row>
                      <v-row no-gutters style="align-self: center">
                        <v-col cols="auto" style="align-self: center">
                          <v-chip v-for="tag in dish.styleTags"
                                  :key="tag.name"
                                  x-small
                          >{{tag.displayName}}</v-chip>
                        </v-col>
                        <v-col cols="auto" style="align-self: center">
                          <v-chip v-for="tag in dish.dietaryTags"
                                  :key="tag.name"
                                  x-small
                          >{{tag.displayName}}</v-chip>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col class="col-auto" style="align-self: center">
                      {{getPriceWithCommission(dish.price).toLocaleString(undefined,{ minimumFractionDigits: 2 })}}
                    </v-col>
                  </v-row>
                </div>
              </div>

              <v-row style="background-color: #f7f3f3;margin: 12px -12px -12px -12px;border-radius: 4px" align="center" justify="center">
                <v-col class="col-auto" >
                  <v-btn @click="book(service.serviceType,chefselected)" color="primary">Book</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>

          <!--        PACKAGES-->
          <div v-for="(pack) in service.packages" :key="pack.id" class="margin-top-default">
          <v-card >
            <v-col align-self="center">
              <v-row style="text-align: center;font-size: medium;font-weight: 700;margin: -12px -12px 0px -12px;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">
                <v-col cols="1" align="start"></v-col>
                <v-col cols="10"><div style="text-align: center;font-size: medium;font-weight: 700;margin: -12px -12px 0px -12px;padding: 12px;border-radius: 4px;background-color: #f7f3f3;">{{service.serviceType.displayName}}: {{pack.name}} <br/> {{formatPackageMinMaxGuests(pack)}}</div></v-col>
                <v-col cols="1" align="end">
                 ${{pack.price}} per person
                </v-col>
              </v-row>

              <div v-for="(packageCategory,indexCat) in packageCategoriesSorted(pack)" :key="packageCategory.name" class="margin-top-default">
                <div v-if="filteredDishesByCategory(service,packageCategory.itemCategory).length>0 ">
                <v-divider v-if="indexCat!=0" style="margin-top: 12px;margin-bottom: 12px;"></v-divider>
                <div class="" style="text-align: center;">
                  <v-row>
                    <v-col align="start">
                      <div style="display:inline;text-align: left;font-weight:bold ;"
                           class="primary--text">
                        <!--                                                      {{dishType.displayName}}-->
                        {{packageCategory.itemCategory.name}} - {{formatPackageCategorySelection(packageCategory)}}
                      </div>
                    </v-col>

                    <v-col class="" cols="2" style="align-self: center">
                    </v-col>
                  </v-row>
                </div>
                <v-row style="text-align: left;" v-for="(dish,indexDish) in filteredDishesByCategoryForPackages(service,packageCategory)"
                       v-bind:key="indexDish">
                  <v-col style="align-self: center;margin-left: 12px;">
                    <v-row no-gutters style="align-self: center">
                      <v-col>
                        {{dish.description}}
                      </v-col>
                    </v-row>
                    <v-row no-gutters style="align-self: center">
                      <v-col cols="auto" style="align-self: center">
                        <v-chip v-for="tag in dish.styleTags"
                                :key="tag.name"
                                x-small
                        >{{tag.displayName}}</v-chip>
                      </v-col>
                      <v-col cols="auto" style="align-self: center">
                        <v-chip v-for="tag in dish.dietaryTags"
                                :key="tag.name"
                                x-small
                        >{{tag.displayName}}</v-chip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                </div>
              </div>
              <v-row style="background-color: #f7f3f3;margin: 12px -12px -12px -12px;border-radius: 4px" align="center" justify="center">
                <v-col class="col-auto" >
                  <v-btn @click="book(service.serviceType,chefselected, pack)" color="primary">Book</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
          </div>

        </div>

      </div>


</div>
<!--  </v-row>-->
</template>
<script>
import StarRating from 'vue-star-rating'
import AboutChef from '../components/AboutChef';
import Vue from "vue";
Vue.component('star-rating', StarRating);
Vue.component('about-chef', AboutChef);
export default {
  name: "Services",
  props: {
    chefselected: Object,
    firstService: Object,
    closeAction: Function,
  },

  data() {
    return {
      rating:5,
      photoIndex: 0,
      index: null,
      gallery: false,
      photoDetails: false,
    };
  },
  mounted() {
  },
  methods: {
    filteredDishesByCategory(service, category) {
      if (service !== undefined && category !== undefined & service.dishes!=undefined) {
        return service.dishes.filter(d => d.itemCategory !== undefined && d.itemCategory.name === category.name)
      } else {
        return [];
      }
    },
    book(serviceType,chefSelected,pack){
      this.$root.serviceSelected=serviceType;
      this.$root.chefSelected=chefSelected;
      this.$root.packageSelected=pack;
      this.$root.dishesSelected=[];
      this.$router.push({path: `/book`});
    },
  },
  computed: {
    serviceOrdered(){
      let result=[];
      console.log(this.firstService);
      let firstService=this.firstService;
      if (firstService!=undefined){
        result.push(firstService);
      }
      this.chefselected.service.forEach( function(item){
            if (firstService ===undefined || item.id!==firstService.id){
              result.push(item);
            }
          }
      );
      return result
    }
  }
}
</script>